<template>
    <div class="unread-num">
        {{num}}
    </div>
</template>

<script>
    export default {
        props:{
            num:Number
        }
    }
</script>

<style scoped>
    .unread-num {
        position: absolute;
        top: -6px;
        right: -6px;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FF7400;
        border-radius: 50%;
        font-size: 9px;
        font-weight: 500;
        color: #FFFFFF;
        display:none;
    }
</style>