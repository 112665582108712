<template>
    <div class="all-page">
        <div class="head-box">
            <div class="first-title-box">
                <div class="first-title" :class="firstType===0?'first-title-check':''" @click="selectFirstType(0)">
                    礼包
                    <div :class="firstType===0?'line':'line-hidden'"></div>
                    <Unread :num="99"></Unread>
                </div>
                <!-- <div class="first-title" :class="firstType===1?'first-title-check':''" @click="selectFirstType(1)">
                    福利
                    <div :class="firstType===1?'line':'line-hidden'"></div>
                    <Unread :num="99"></Unread>
                </div> -->
            </div>
            <div class="second-title-box" v-if="secondTypeList.length>0">
                <div class="second-title-item-box" v-for="(item, index) in secondTypeList" :key="index">
                    <div @click="changeGiftType(item)" class="second-title-item" :class="secondType===item.gift_cat_id?'second-title-item-check':''">
                        {{item.gift_cat_name}}
                       <Unread :num="99"></Unread>
                    </div>
                </div>
            </div>

        </div>
        <div class="all-page-content-box" @scroll="scrollList" ref="scrollBox" v-if="giftList.length>0">
            <div class="gift-list-box">
                <div class="gift-item-box"  v-for="(item, index) in giftList" :key="index">
                    <div class="gift-item">
                        <div class="gift-info" @click="getGiftDetail(item)">
                           <div class="gift-name">
                               {{item.gift_name}}
                           </div>
                           <div class="gift-content">
                               {{item.content}}
                           </div>
                           <div class="gift-content">
                               有效期至 {{item.get_end_time | changTimeType}}
                           </div>
                        </div>
                        <div class="get-btn" @click="getGift(item)" v-if="item.is_receive === 0">
                            领取
                        </div>
                        <div class="copy-btn" v-else @click="copyCdk(item)">
                            复制
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BlankList v-else type="gift"></BlankList>
    </div>
</template>

<script>
    import Unread from '@/components/Common/Vertical/Unread.vue'
    import BlankList from "@/components/Common/Vertical/BlankList.vue"
    
    export default {
        components: {
            Unread,
            BlankList
        },
        props: {
            firstType: Number,
            secondType: Number,
            secondTypeList: Array,
            giftList:Array
        },
        data() {
            return {
                
            }
        },
        methods: {
            selectFirstType(type) {
                this.$emit('selectFirstType', type)
            },
            changeGiftType(index) {
                this.$emit('changeGiftType', index)
            },
            getGiftDetail(item) {
                this.$emit('getGiftDetail', item)
            },
			scrollList(e) {
			    this.$nextTick(()=> {
			        this.$emit('scrollList', this.$refs.scrollBox)
			    })
			},
            copyCdk(e) {
                this.$emit('copyCdk', e)
            },
			getGift(e) {
				this.$emit('getGift', e)
			}
        }
    }
</script>

<style scoped>
    .all-page {
        background-color: #F4F4F4;
        width: 100%;
    }

    .head-box {
        padding-bottom: 5px;
        width: 100%;
    }

    .first-title-box {
        background-color: #ffffff;
        padding-top: 22px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        font-weight: 500;
        color: #666666;
        /* padding: 22px 120px 4px 120px; */
    }

    .first-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }

    .first-title-check {
        color: #16C5CE;
    }

    .line {
        width: 19px;
        height: 3px;
        background: #16C5CE;
        border-radius: 2px;
        margin-top: 2px;
    }

    .line-hidden {
        height: 3px;
        margin-top: 2px;
    }

    .second-title-box {
        background-color: #ffffff;
        height: 38px;
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: scroll;
        padding-right: 8px;
    }

    .second-title-item-box {
        padding-left: 9px;
    }

    .second-title-item {
        padding: 0 10px;
        white-space: nowrap;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #EBEBEB;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .second-title-item-check {
        border: none;
        background-color: #EBEBEB;
        color: #000000;
    }
    .gift-list-box {
        padding: 19px 22px;
        background-color: #ffffff;
    }
    .gift-item-box {
        padding-bottom: 20px;
    }
    .gift-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .gift-info {
        flex: 1;
        padding-right: 20px;
        overflow: hidden;
    }
    .gift-name {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
    .gift-content {
        padding-top: 5px;
        width: 80%;
        overflow: hidden;
         display:-webkit-box;
          text-overflow: ellipsis;
         -webkit-box-orient:vertical;
         -webkit-line-clamp:2;
         /* 解决字母不换行问题 */
         word-break: break-all;
         /* 解决中文标点不换行的问题 */
         line-break: anywhere;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
    }
    .get-btn,
    .copy-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        width: 60px;
        height: 28px;
    }
    .get-btn {
        background: #16C5CE;
        color: #FFFFFF;
    }
    .copy-btn {
        border: 1px solid #00C9D0;
        color: #00C9D0;
    }
	.all-page-content-box {
		background-color: #ffffff;
	}
</style>
